import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import * as queryString from "query-string"
import { Row, Col, Container, Table } from "reactstrap"
import Link from "../components/link"
import Styled from "styled-components"
import SEO from "../components/seo"
import TitleBanner from "../components/titleBanner/TitleBanner"
import RegistrationWithDetails from "../components/contact/RegistrationWithDetails"

const Cirle = Styled.div`
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

let StyledRow = Styled(Row)`
  justify-content: center;
`

let ContactItem = ({
  text,
  type,
  Icon,
  href,
  children,
}) => (
  <Col
    xs={6}
    lg={3}
    className="d-flex flex-column align-items-center"
    style={{
      wordBreak: "break-word",
    }}
  >
    <Cirle className="h-4-rem w-4-rem bg-primary-faded my-3 absolute-center rounded-circle">
      <Icon size={30} />
    </Cirle>
    <div className="text-center">
      <h6>{type}</h6>
      <span className="text-secondary">
        {href ? (
          <Link to={href} className="reset">
            {children ? children : text}
          </Link>
        ) : children ? (
          children
        ) : (
          text
        )}
      </span>
    </div>
  </Col>
)

const Registration = ({ data, location }) => {
  const [session, setSession] = useState(null)
  let { address, email, phone, fax } =
    data.site.siteMetadata
  let { session_id } = queryString.parse(location.search)

  return (
    <>
      <SEO
        title="Course Registration | Ace Industrial Academy"
        description="Course Registration | Ace Industrial Academy"
      />

      <TitleBanner
        title="Contact Us"
        description="Reach out to us for training dates and course fees"
        withOnlyDescription
        hasPlainBanner
      />

      <RegistrationWithDetails />
    </>
  )
}

export const query = graphql`
  query Registration {
    site {
      siteMetadata {
        address
        email
        phone
        fax
      }
    }
  }
`

export default Registration
