import React from "react"
import { API } from "aws-amplify"
import {
  FormContainer,
  FormTitle,
  FormDescription,
  FormField,
} from "./styles"
import { useForm } from "react-hook-form"
import { sesCall } from "../../../graphql/queries"
import { isBrowser } from "../../../context/utils"

const RegistrationForm = (props) => {
  const { setisMessageSent, title } = props
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({ shouldUseNativeValidation: true })

  const selectedCourse = isBrowser()
    ? window.localStorage.getItem("selected-course")
    : null

  const onSubmit = (data) => {
    sesNotification(data)
    setisMessageSent(true)
  }
  

  async function sesNotification(data) {
    const { name, email, phone, courseTitle, organisation, designation } =
      data
    try {
      await API.graphql({
        query: sesCall,
        variables: {
          msg: {
            name: name,
            email: email,
            phone: phone,
            course: courseTitle,
            organisation: organisation,
            designation: designation
          },
        },
      })
    } catch (err) {
      console.log("error triggering lambda function: ", err)
    }
  }

  return (
    <FormContainer className="contactForm">
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormField>
            <label>Course Title</label>
            <input
              type="text"
              name="courseTitle"
              value={selectedCourse}
              placeholder={selectedCourse}
              {...register("courseTitle")}
            />
          </FormField>
          <FormField>
            <label>Full Name</label>
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              {...register("name", { required: true })}
            />
          </FormField>
          <FormField>
            <label>Phone</label>
            <input
              type="text"
              name="phone"
              placeholder="98888888"
              {...register("phone", { required: true })}
            />
          </FormField>
          <FormField>
            <label>Email Address</label>
            <input
              type="email"
              name="name"
              placeholder="Email Address"
              {...register("email",{ required: true })}
            />
          </FormField>
          <FormField>
            <label>Organisation</label>
            <input
              type="text"
              name="organisation"
              placeholder=""
              {...register("organisation")}
            />
          </FormField>
          <FormField>
            <label>Designation</label>
            <input
              type="text"
              name="designation"
              placeholder=""
              {...register("designation")}
            />
          </FormField>
          <FormField>
            <input
              type="submit"
              name="sbtn"
              value="Send Message"
            />
          </FormField>
        </form>
      </div>
    </FormContainer>
  )
}

export default RegistrationForm
