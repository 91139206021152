import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import RegistrationForm from "./form/RegistrationForm"
import {
  AddressInfoContainer,
  AddressInfoCover,
  AnimationContainer,
  ContactContentWrapper,
  ContactIconCover,
  InfoContent,
  SuccessMessageContainer,
} from "./styles"
import Lottie from "lottie-react"
import successAnimation from "../../data/message-sent-success.json"

const RegistrationWithDetails = () => {
  const [isMessageSent, setisMessageSent] = useState(false)

  return (
    <div className="container">
      <ContactContentWrapper>
        <AddressInfoContainer>
          <AddressInfoCover>
            <ContactIconCover>
              <StaticImage
                src="../../assets/images/phone-icon.png"
                alt=""
              />
            </ContactIconCover>
            <InfoContent>
              <h3>Phone Number</h3>
              <h4>9724 6593</h4>
            </InfoContent>
          </AddressInfoCover>
          <AddressInfoCover>
            <ContactIconCover>
              <StaticImage
                src="../../assets/images/email-icon.png"
                alt=""
              />
            </ContactIconCover>
            <InfoContent>
              <h3>Email Address</h3>
              <h4>training@aia.edu.sg</h4>
            </InfoContent>
          </AddressInfoCover>
          <AddressInfoCover>
            <ContactIconCover>
              <StaticImage
                src="../../assets/images/location-icon.png"
                alt=""
              />
            </ContactIconCover>
            <InfoContent>
              <h3>Address</h3>
              <h4>159 Sin Ming Road, Singapore 575625</h4>
            </InfoContent>
          </AddressInfoCover>
        </AddressInfoContainer>
        {isMessageSent ? (
          <SuccessMessageContainer>
            <AnimationContainer>
              <Lottie
                animationData={successAnimation}
                loop={false}
              />
            </AnimationContainer>
          </SuccessMessageContainer>
        ) : (
          <RegistrationForm
            setisMessageSent={setisMessageSent}
          />
        )}
      </ContactContentWrapper>
    </div>
  )
}

export default RegistrationWithDetails
